<script setup lang="ts">
import AppExpertCard from '@/components/AppExpertCard.vue'
import { ref } from 'vue'
import AppMainExpertCard from '@/components/AppMainExpertCard.vue'

const main = ref({
  title: 'expert_title',
  name: 'expert_name',
  image: 'tania.jpg',
  description: 'expert_description'
})
const experts = ref([{
  title: 'expert1_title',
  name: 'expert1_name',
  image: 'alla.jpg',
  description: 'expert1_description'
}, {
  title: 'expert2_title',
  name: 'expert2_name',
  image: 'angelina.jpg',
  description: 'expert2_description'
}, {
  title: 'expert3_title',
  name: 'expert3_name',
  image: 'valia.jpg',
  description: 'expert3_description'
}])
</script>

<template>
  <div class="container app-experts">
    <h1 class="text-center p-3 pt-xl-5">{{$t('our_team')}}</h1>

    <div class="py-5">
      <AppMainExpertCard :title="main.title" :name="main.name" :description="main.description" :image="main.image"/>
    </div>

    <div class="row">
      <div class="col-12 col-md-6 col-xl-4 py-3" v-for="expert in experts">
        <AppExpertCard :title="expert.title" :name="expert.name" :description="expert.description" :image="expert.image"/>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../assets/vars";

</style>
