import './assets/main.scss'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import i18n from './i18n'
import { createHead } from '@vueuse/head'

const app = createApp(App).use(i18n)
const head = createHead()

app.use(router)
app.use(PrimeVue)
app.use(head)

app.mount('#app')
