<script setup lang="ts">
import { ref } from 'vue'

const prices = ref([{
  service: 'manicure',
  list: [{
    name: 'combi_manicure_gel_polish',
    price: '750',
    duration: '60 mins'
  }, {
    name: 'combi_manicure',
    price: '750',
    duration: '60 mins'
  }, {
    name: 'hard_gel',
    price: '750',
    duration: '60 mins'
  }, {
    name: 'repair',
    price: '750',
    duration: '60 mins'
  }, {
    name: 'french_manicure',
    price: '750',
    duration: '60 mins'
  }, {
    name: 'nail_extension',
    price: '750',
    duration: '60 mins'
  }, {
    name: 'nail_extension_xl',
    price: '750',
    duration: '60 mins'
  }, {
    name: 'removing_nail_extension',
    price: '750',
    duration: '60 mins'
  }, {
    name: 'correction_extended_nails',
    price: '750',
    duration: '60 mins'
  }, {
    name: 'mens_manicure',
    price: '750',
    duration: '60 mins'
  }]
}, {
  service: 'pedicure',
  list: [{
    name: 'full_pedicure_gel',
    price: '750',
    duration: '60 mins'
  }, {
    name: 'pedicure_gel',
    price: '750',
    duration: '60 mins'
  }, {
    name: 'full_pedicure',
    price: '750',
    duration: '60 mins'
  }, {
    name: 'mens_pedicure_only_fingers',
    price: '750',
    duration: '60 mins'
  }, {
    name: 'mens_pedicure',
    price: '750',
    duration: '60 mins'
  }]
}])
</script>
<template>
  <!--List of pricing TODO-->
  <section class="section section-md bg-default text-center pt-5 app-prices">
    <div class="container">
      <h1 class="app-prices__title">{{ $t('prices')}}</h1>
      <div class="divider-lg"></div>
      <p class="block-sm">{{ $t('price_description')}}</p>
      <div class="row justify-content-center">
        <div id="accordion" class="accordion-prices2">
          <div v-for="price in prices">
            <h3 class="pt-5 pb-3">{{ $t(price.service) }}</h3>
            <div class="col-12 row g-0">
              <div class="col-12 col-md-6 text-start p-3 pe-md-5 row g-0"
                   v-for="priceItem in price.list">
                <div class="col-8">
                  <h5 class="app-prices__price-item-name">{{ $t(priceItem.name) }}</h5>
                </div>
                <div class="text-end col-2">
                  <p class="app-prices__duration">{{ priceItem.duration }}</p>
                </div>
                <div class="price-value text-end col-2">
                  <h5>{{ priceItem.price }} Kč</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.app-prices {

  &__title {
    animation-name: animation_font_size;
    animation-duration: 1s;
    font-weight: 500;
  }

  &__price-item-name {
    animation-name: animation_spacing;
    animation-duration: 1s;
    font-weight: 500;
  }
}

@media (max-width: 992px) {
  .app-prices__duration, h5 {
    font-size: 16px;
  }
}

@media (max-width: 650px) {
  .app-prices__duration, h5 {
    font-size: 14px;
  }
}

@keyframes animation_font_size {
  from {
    font-size: 20px;
  }
}

@keyframes animation_spacing {
  from {
    letter-spacing: -5px;
  }
}

</style>
