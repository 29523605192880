<script setup lang="ts">
import { useHead } from '@unhead/vue'
import i18n from '@/i18n'

useHead({
  htmlAttrs: {
    lang: i18n.global.locale.value
  }
})
</script>

<template>
  <router-view />
</template>
