<template>
  <ul class="text-md-end app-social-block py-1 py-md-0 w-100">
    <li><a class="px-3 px-md-2 fa fa-map-marker" href="https://maps.app.goo.gl/osCGocoGM2UT1wJy6" target="_blank"></a></li>
    <li><a class="px-3 px-md-2 fa fa-phone" href="tel:+420 702 094 842" target="_blank"></a></li>
    <li><a class="px-3 px-md-2 fa fa-instagram" href="https://www.instagram.com/beauty_couture_studio" target="_blank"></a></li>
    <li><a class="px-3 px-md-2 fa fa-facebook" href="https://www.facebook.com/nailcoutureprague" target="_blank"></a></li>
    <li><a class="px-3 px-md-2 fa fa-whatsapp" href="https://wa.me/message/Y7EDOOCYL3MIA1" target="_blank"></a></li>
    <li><a class="px-3 px-md-2 fa fa-telegram" href="https://t.me/beauty_couture_studio" target="_blank"></a></li>
    <li><a class="px-3 px-md-2 fa fa-google" href="mailto:nailcoutureprague@gmail.com" target="_blank"></a></li>
  </ul>
</template>
<style lang="scss" scoped>
.app-social-block {
  display: inline-block;
  font-size: 14px;
  height: 100%;
  vertical-align: middle;

  text-align: right;
  width: 30px;
  padding-right: 20px;

  > * {
    display: inline-block;
  }

  & a {
    width: 40px;
    font-size: 20px;
  }
}

a {
  color: rgba(21, 21, 21, .3);

  &:hover {
    color: #fff;
  }
}
</style>
<script setup lang="ts">
</script>
