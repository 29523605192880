import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PricesView from '@/views/PricesView.vue'
import ServicesView from '@/views/ServicesView.vue'
import ExpertsView from '@/views/ExpertsView.vue'
import ContactsView from '@/views/ContactsView.vue'
import ParentView from '@/router/ParentView.vue'
import i18n, { authorisedLanguages, locales } from '@/i18n'
import NotFoundView from '@/views/NotFoundView.vue'
import { useHead } from '@unhead/vue'

const routers: RouteRecordRaw[] = [
  {
    path: '',
    name: 'redirect-to-home',
    redirect: function(to: RouteLocationNormalized) {
      return to.path + '/home'
    }
  },
  {
    path: 'home',
    name: 'home',
    component: HomeView
  },
  {
    path: 'services',
    name: 'services',
    component: ServicesView
  },
  {
    path: 'prices',
    name: 'prices',
    component: PricesView
  },
  {
    path: 'experts',
    name: 'experts',
    component: ExpertsView
  },
  {
    path: 'contacts',
    name: 'contacts',
    component: ContactsView
  },
  {
    path: '/:lang/:pathMatch(.*)*',
    name: 'not-found',
    component: NotFoundView
  }
].map(state => ({
  ...state,
  beforeEnter: (to) => {
    useHead({
      link: [...locales.map(locale => ({
        key: locale.locale,
        rel: 'alternate',
        href: 'https://beauty-couture-studio.cz/' + locale.lang + '/' + (to.name as string),
        hreflang: locale.locale
      }))],
      meta: [{
        name: 'og:url',
        property: 'og:url',
        content: 'https://beauty-couture-studio.cz' + to.path
      }, {
        name: 'og:locale',
        property: 'og:locale',
        content: i18n.global.locale.value
      }]
    })
  }
}))

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  end: true,
  routes: [
    {
      path: '/',
      name: 'root',
      redirect: function(to) {
        return '/' + authorisedLanguages[0]
      }
    },
    {
      path: '/:lang',
      name: 'lang',
      component: ParentView,
      children: [...routers],
      beforeEnter: (to, from, next) => {
        const lang = to.path.split('/', 2)[1]
        if (!authorisedLanguages.includes(lang)) {
          return next(authorisedLanguages[0] + '/' + (to.name as string))
        }
        return next()
      }
    }
  ]
})

export default router
