<script setup lang="ts">
</script>

<template>
  <!--Map-->
  <section class="app-map-section row m-0">
    <div class="col-xl-6 col-sm-12">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20482.8398287149!2d14.383554577642396!3d50.07964040000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470b95d1cfb55689%3A0x65d50cf74d75c856!2sBeauty%20Couture%20Studio!5e0!3m2!1sen!2scz!4v1715628898839!5m2!1sen!2scz"
              width="100%" height="100%" style="border:0;min-height: 300px;max-height: 600px;" allowfullscreen loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
    </div>
    <div class="col-xl-6 col-sm-12 px-sm-5">
      <div class="contact-info py-4 p-xl-4 text-center text-xl-start">
        <h3 class="contact-info-title">{{$t('contact_information')}}</h3>
        <div class="divider-lg contact-info-divider"></div>
        <ul class="list">
          <li>
            <span class="icon fa fa-map-marker"></span>
            <a href="https://maps.app.goo.gl/osCGocoGM2UT1wJy6">Zborovská 575/41, 150 00 Malá Strana</a>
          </li>
          <li>
            <span class="icon fa fa-phone"></span>
            <a href="tel:+420 702 094 842">+420 702 094 842</a>
          </li>
          <li>
            <span class="icon fa fa-instagram"></span>
            <a href="https://www.instagram.com/beauty_couture_studio">@nail_couture_prague</a>
          </li>
          <li>
            <span class="icon fa fa-whatsapp"></span>
            <a href="https://wa.me/message/Y7EDOOCYL3MIA1">WhatsApp: Beauty Couture Studio</a>
          </li>
          <li>
            <span class="icon fa fa-facebook"></span>
            <a href="https://www.facebook.com/nailcoutureprague">@nailcoutureprague</a>
          </li>
          <li>
            <span class="icon fa fa-telegram"></span>
            <a href="https://t.me/beauty_couture_studio">@beauty_couture_studio</a>
          </li>
          <li>
            <span class="icon fa fa-google"></span>
            <a href="mailto:nailcoutureprague@gmail.com">nailcoutureprague@gmail.com</a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>
<style lang="scss" scoped>
@import "../assets/vars";
.app-map-section {
  background-color: $background-color;
}

a {
  color: #151515;
}

a:hover {
  color: $main-color;
}

.contact-info-title {
  color: #151515;
  font-weight: 700;
}

.contact-info-divider {
  padding-bottom: 15px;
  padding-top: 15px;
}

.contact-info span[class*="icon"] {
  width: 20px;
}
</style>
